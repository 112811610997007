import Groq from '../../node_modules/groq-sdk';
import { marked } from '../../node_modules/marked';


const apiKey = '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'; // Assurez-vous de remplacer 'votre_cle_api' par votre vraie clé API

const groq = new Groq({ apiKey, dangerouslyAllowBrowser: true });

export async function main(request) {
  var test = ''
  var div = document.createElement('div');
  div.classList.add('ia');
  div.classList.add('reponseIA');
  document.querySelector('#chatbot').appendChild(div);
  var divIA = document.querySelector('.ia');
  divIA.innerHTML = "..."
  const stream = await getGroqChatStream(request);
  var test = ''
  for await (const chunk of stream) {
    // Print the completion returned by the LLM.

    var content = chunk.choices[0]?.delta?.content || "";
    console.log(content);
    test += content;

    // console.log(chunk.choices[0]?.delta?.content || "");

    // test += chunk.choices[0]?.delta?.content
    // divIA.innerHTML += chunk.choices[0]?.delta?.content || "";
  }
  divIA.innerHTML = marked.parse(test);
  divIA.classList.remove('ia');
  console.log(test)
  mainA(test)
}

export async function getGroqChatStream(request) {
  return groq.chat.completions.create({
    //
    // Required parameters
    //
    messages: [
      // Set an optional system message. This sets the behavior of the
      // assistant and can be used to provide specific instructions for
      // how it should behave throughout the conversation.
      {
        role: "system",
        content: "tu est un développeur français qui s'appelle luigi, t'on travail est de faire de répondre à mes questions le mieux possible. Je vais te demander de répondre au probleme du message en faissant un framework personnalisé et complet avec pas mal de contenue pour répondre a mon problème",
      },
      // Set a user message for the assistant to respond to.
      {
        role: "user",
        content: request,
      },
    ],

    // The language model which will generate the completion.
    model: "gemma2-9b-it",

    //
    // Optional parameters
    //

    // Controls randomness: lowering results in less random completions.
    // As the temperature approaches zero, the model will become deterministic
    // and repetitive.
    temperature: 0.5,

    // The maximum number of tokens to generate. Requests can use up to
    // 2048 tokens shared between prompt and completion.
    max_tokens: 1024,

    // Controls diversity via nucleus sampling: 0.5 means half of all
    // likelihood-weighted options are considered.
    top_p: 1,

    // A stop sequence is a predefined or user-specified text string that
    // signals an AI to stop generating content, ensuring its responses
    // remain focused and concise. Examples include punctuation marks and
    // markers like "[end]".
    stop: null,

    // If set, partial message deltas will be sent.
    stream: true,
  });
}


export async function mainA(request) {
  console.log('tests')
  const stream = await analyse(request);
  var tests = ''
  for await (const chunk of stream) {
    // Print the completion returned by the LLM.
    tests += chunk.choices[0]?.delta?.content
    console.log(chunk.choices[0]?.delta?.content || "");
  }
  console.log(tests)
}

export async function analyse(request) {
  return groq.chat.completions.create({
    //
    // Required parameters
    //
    messages: [
      // Set an optional system message. This sets the behavior of the
      // assistant and can be used to provide specific instructions for
      // how it should behave throughout the conversation.
      {
        role: "system",
        content: "je vais te donner le prompt d'une ia tu doit en extraire le css, html et js si il y en a, enlève le texte en trop et enlève les backtick",
      },
      // Set a user message for the assistant to respond to.
      {
        role: "user",
        content: request,
      },
    ],

    // The language model which will generate the completion.
    model: "llama3-8b-8192",

    //
    // Optional parameters
    //

    // Controls randomness: lowering results in less random completions.
    // As the temperature approaches zero, the model will become deterministic
    // and repetitive.
    temperature: 0.5,

    // The maximum number of tokens to generate. Requests can use up to
    // 2048 tokens shared between prompt and completion.
    max_tokens: 1024,

    // Controls diversity via nucleus sampling: 0.5 means half of all
    // likelihood-weighted options are considered.
    top_p: 1,

    // A stop sequence is a predefined or user-specified text string that
    // signals an AI to stop generating content, ensuring its responses
    // remain focused and concise. Examples include punctuation marks and
    // markers like "[end]".
    stop: null,

    // If set, partial message deltas will be sent.
    stream: true,
  });
}

function debounce(callback, delay) {
  var timer;
  return function () {
    var args = arguments;
    var context = this;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, delay)
  }
};


class Textarea extends HTMLTextAreaElement {
  constructor() {
    super();
    this.onFocus = this.onFocus.bind(this);
    this.onResize = debounce(this.onResize.bind(this), 300);
    this.autogrow = this.autogrow.bind(this);
  }

  connectedCallback() {
    this.addEventListener('focus', this.onFocus)
    this.style.resize = `none`;
    this.style.boxSizing = `border-box`;
  }

  disconnecterCallback() {
    window.removeEventListener('resize', this.onResize)
  }

  onFocus() {
    console.log('onFocus');
    // this.autogrow()
    window.addEventListener('resize', this.onResize)
    this.addEventListener('input', this.autogrow)
    this.removeEventListener('focus', this.onFocus)
  }

  onResize() {
    console.log('onResize')
    this.autogrow()
  }

  autogrow() {
    console.log('grow')
    this.style.height = 'auto';
    this.style.height = this.scrollHeight + 'px';
    if (this.scrollHeight > 250) {
      this.style.overflowY = `scroll`;
    }
  }

}



customElements.define('textarea-autogrow', Textarea, { extends: 'textarea' });


addEventListener('keydown', (event) => {
  console.log(event)
  if (event.key === 'Enter' || event == '13') {
    console.log('Enter')
    requete();
  }

})

function requete() {
  const tests = document.querySelector('textarea').value
  document.querySelector('.input').removeChild(document.querySelector('textarea'))

  const textarea = document.createElement('textarea');
  textarea.id = 'aera';
  textarea.className = 'w-100';
  textarea.rows = 1;
  textarea.placeholder = 'Entrer votre question ...';
  document.querySelector('.input').appendChild(textarea);
  main(tests)
}




function extractCodeSections(text) {
  const sections = {
    html: '',
    css: '',
    js: ''
  };

  // Expressions régulières pour correspondre aux sections HTML, CSS et JavaScript
  const htmlPattern = /HTML\s*:\s*```html([^`]+)```/s;
  const cssPattern = /CSS\s*\(style\.css\)\s*:\s*```css([^`]+)```/s;
  const jsPattern = /JavaScript\s*:\s*```js([^`]+)```/s;

  // Correspondance des sections dans le texte
  const htmlMatch = text.match(htmlPattern);
  const cssMatch = text.match(cssPattern);
  const jsMatch = text.match(jsPattern);

  // Extraction des sections trouvées
  if (htmlMatch) {
    sections.html = htmlMatch[1].trim();
  }
  if (cssMatch) {
    sections.css = cssMatch[1].trim();
  }
  if (jsMatch) {
    sections.js = jsMatch[1].trim();
  }

  return sections;
}

function extraction() {
  var sections = {
    html: '',
    css: '',
    js: ''
  };

  // Exemple d'utilisation
  var text = `
Voici les extraits de code HTML, CSS et JavaScript :

**HTML :**
\`\`\`html
<!DOCTYPE html>
<html>
<head>
  <title>ColFlex Example</title>
  <link rel="stylesheet" href="style.css">
</head>
<body>
  <div class="container">
    <div class="col col-sm-6 col-md-4 col-lg-3">Colonne 1</div>
    <div class="col col-sm-6 col-md-4 col-lg-3">Colonne 2</div>
    <div class="col col-sm-12 col-md-4 col-lg-3">Colonne 3</div>
  </div>
</body>
</html>
\`\`\`

**CSS (style.css) :**
\`\`\`css
.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 15px;
}

.col {
  float: left; /* Ajustement pour les colonnes */
  box-sizing: border-box; /* Inclure les bordures et les marges dans la largeur */
  padding: 15px;
}

/* Définition des tailles de colonnes */
@media (min-width: 768px) { /* Point de rupture pour les tablettes */
  .col-sm-1 {
    width: 8.33%;
  }
  .col-sm-2 {
    width: 16.66%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33%;
  }
  .col-sm-5 {
    width: 41.66%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33%;
  }
  .col-sm-8 {
    width: 66.66%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33%;
  }
  .col-sm-11 {
    width: 91.66%;
  }
  .col-sm-12 {
    width: 100%;
  }
  /* etc. pour les autres tailles */
}
\`\`\`

Il n'y a pas de code JavaScript dans l'exemple fourni.
`;
  const regex = /`/g;
  var test = ''
  for (const lettre of text) {
    test += lettre.replace(regex, '')

  }
  text = test
  for (const lettre of text) {
    if (lettre.match('/h/')) {
      if (lettre.match('/t/')) {
        if (lettre.match('/m/')) {
          if (lettre.match('/l/')) {
            
          }
        }
      }
    }
  }
}

extraction()